import * as THREE from "three";
import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import earthImg from "../assets/world-map.gif";
const SphereComp = () => {
  const base = new THREE.TextureLoader().load(earthImg);
  const ref = useRef();
  useFrame(() => (ref.current.rotation.y += 0.01));
  return (
    <mesh visible castShadow ref={ref}>
      <directionalLight intensity={1} />
      <sphereGeometry attach="geometry" args={[2.9, 32, 32]} />
      <meshBasicMaterial map={base} color="white" />
    </mesh>
  );
};
const Sphere = () => {
  return (
    <div style={{ minHeight: "2.5em", minWidth: "2em" }}>
      <Canvas>
        <ambientLight />
        <SphereComp />
      </Canvas>
    </div>
  );
};

export default Sphere;
