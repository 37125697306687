import React, { useEffect, useState } from "react";
import MediumCards from "../components/BlogComponents/MediumCards";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
import { Link } from "gatsby";
import iconF1 from "../assets/image/svg/database.svg";
import iconF2 from "../assets/image/svg/no-data.svg";
import ogImage from "../assets/extra/global/link-logo-white.png";
import Differentiation from "../components/Differentiation";
import Hero from "../sections/landing6/Hero";
import TxnApiDemo from "../components/APIDemos/TxnApiDemo";
import { Row, Col, Container, Button } from "react-bootstrap";
import HomeFeatures from "../sections/landing1/HomeFeatures";
import ValueProp from "../components/ValueProp";
import ScrollTest from "../components/ScrollTest";
import CategorizationAd from "../components/CategorizationAd/CategorizationAd";
import Lottie from "lottie-react";
import MenaAnimation from "../components/lottie_animation/Genfiy Animation 2.0.json";

import CreditScoringDemoV2 from "../components/APIDemos/CreditScoringDemoV2";
import TxnApiDemoV2 from "../components/APIDemos/TxnApiDemov2";
import ProdRecomDemoV2 from "../components/APIDemos/ProdRecomDemoV2";

const Test = () => {
  const [authUrl, setAuthUrl] = useState("");

  const fetchAuthUrl = async () => {
    const clientId = "85878";
    const redirectUri = "http://genify.ai";
    const responseType = "code";
    const authUrl = `https://public-api.wordpress.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}/`;

    // const response = await fetch(authUrl, {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // });
    fetch(
      "https://public-api.wordpress.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}/"
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("blogs:", data);
      })
      .catch((error) => {
        console.log(error);
      });
    // const data = await response.json();
    // console.log(data);
  };
  const [currentComponent, setCurrentComponent] = useState(
    <CreditScoringDemoV2 />
  );

  const interactivity = {
    mode: "scroll",
    actions: [
      {
        visibility: [0, 0.2],
        type: "stop",
        frames: [0],
      },
      {
        visibility: [0, 1],
        type: "seek",
        frames: [0, 600],
      },
    ],
  };
  return (
    <>
      <Helmet title="Blog | Genify" defer={false} />
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
          path: "blogPage",
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <div>
          <CategorizationAd />
        </div>
        <Lottie animationData={MenaAnimation} interactivity={interactivity} />
        <>
          {/* <!-- Category section --> */}
          <div className="category-section pl-lg-20 pt-12 pb-6 pt-lg-26 pb-lg-24  ">
            <Row>
              <Col>
                <Row>
                  <Col
                    md="8"
                    lg="10"
                    data-aos="fade-right"
                    data-aos-duration="1100"
                  >
                    <Button
                      onClick={() => setCurrentComponent(<TxnApiDemoV2 />)}
                      className="d-flex justify-content-between rounded-10 px-9 pt-8 pb-8 align-items-center mb-11 category-card"
                    >
                      <div className="texts light-mode-texts">
                        <h3 className="card-title gr-text-7-card  font-weight-bold">
                          Transaction Categorization API
                        </h3>
                        <p className=" gr-text-11-card  mb-0 ">
                          Our API enriches transaction data with five fields:
                          category, merchant logo, clean merchant name, merchant
                          website, and carbon footprint.
                        </p>
                      </div>
                      <div
                        className="card-icon circle-43"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open("https://docs.pfm.genify.ai/", "_blank");
                        }}
                      >
                        <i className="fas fa-book gr-text-8-card text-white"></i>
                      </div>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="8"
                    lg="10"
                    data-aos="fade-right"
                    data-aos-duration="1100"
                  >
                    <Button
                      onClick={() =>
                        setCurrentComponent(<CreditScoringDemoV2 />)
                      }
                      className="d-flex justify-content-between rounded-10 px-9 pt-8 pb-8 align-items-center mb-11 category-card"
                    >
                      <div className="texts light-mode-texts">
                        <h3 className="card-title gr-text-7-card font-weight-bold">
                          Credit Scoring API
                        </h3>
                        <p className="gr-text-11-card mb-0 ">
                          Our API lets you collect relevant data points from
                          your users’ phone and apply your credit decisioning
                          policy.
                        </p>
                      </div>
                      <div
                        className="card-icon circle-43"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            "https://docs.ezloan.genify.ai/",
                            "_blank"
                          );
                        }}
                      >
                        <i className="fas fa-book gr-text-8-card text-white"></i>
                      </div>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="8"
                    lg="10"
                    data-aos="fade-right"
                    data-aos-duration="1100"
                  >
                    <Button
                      onClick={() => setCurrentComponent(<ProdRecomDemoV2 />)}
                      className="d-flex justify-content-between rounded-10 px-9 pt-8 pb-8 align-items-center mb-11 category-card"
                    >
                      <div className="texts light-mode-texts">
                        <h3 className="card-title gr-text-7-card font-weight-bold">
                          Recommendation API
                        </h3>
                        <p className="gr-text-11-card mb-0 ">
                          Our API recommends the next-best action or next-best
                          product individually for each user.
                        </p>
                      </div>
                      <div
                        className="card-icon circle-43"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            "https://docs.pfm.genify.ai/pfm-suite/v1/restricted-access/recommendation-api",
                            "_blank"
                          );
                        }}
                      >
                        <i className="fas fa-book gr-text-8-card text-white"></i>
                      </div>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>{currentComponent}</Col>
            </Row>
            <div>
              <button onClick={fetchAuthUrl}>Authorize</button>
            </div>
          </div>
        </>
      </PageWrapper>
    </>
  );
};

export default Test;
