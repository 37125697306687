import React, { useEffect, useRef, useState } from "react";
import "./CategorizationAd.css";
import TypeWriter from "typewriter-effect";
import { AU, EG, GB } from "country-flag-icons/react/3x2";
import { AE } from "country-flag-icons/react/3x2";
import { EU } from "country-flag-icons/react/3x2";
import { US } from "country-flag-icons/react/3x2";
import { SA } from "country-flag-icons/react/3x2";
import { Canvas } from "@react-three/fiber";
import Sphere from "../Sphere";
import { Col, Container, Row } from "react-bootstrap";

const flags = [
  <EU title="Europe" className="flag" />,
  <US title="United States" className="flag" />,
  <AE title="United Arab Emirates" className="flag" />,
  <EG title="Egypt" className="flag" />,
  <AU title="Australia" className="flag" />,
  <GB title="United Kingdom" className="flag" />,
];

const CategorizationAd = () => {
  const [currentFlagIdx, setCurrentFlagIdx] = useState(null);
  const [currentScroll, setCurrentScroll] = useState(0);
  const flagRef = useRef(null);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const flagRect = flagRef.current.getBoundingClientRect();
    const flagTop = flagRect.top;
    const start = 0.8 * window.innerHeight;
    const end = 0.3 * window.innerHeight;
    if (!(flagTop < start && flagTop > end)) {
      setCurrentFlagIdx(null);
    } else if (currentFlagIdx === null) {
      setCurrentFlagIdx(0);
    } else if (Math.abs(scrollY - currentScroll) >= 80) {
      scrollY < currentScroll
        ? setCurrentFlagIdx((currentFlagIdx - 1) % flags.length)
        : setCurrentFlagIdx((currentFlagIdx + 1) % flags.length);
    } else return;
    setCurrentScroll(scrollY);

    // const flagCenter = flagRect.top + flagRect.height / 2;
    // const screenCenter = window.innerHeight / 2;
    // const isFlagAtCenter = Math.abs(flagCenter - screenCenter) < 10;
    // console.log("Flag is at center:", isFlagAtCenter);
  };

  useEffect(() => {
    setCurrentScroll(flagRef.current.getBoundingClientRect().top);
  }, [flagRef]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentScroll]);

  return (
    <Container>
      <Row className="justify-content-center ">
        <Col lg="9" xl="8">
          <div className="px-md-15 text-center pt-sm-15">
            <h2 className="title title-gui gr-text-2 mb-2" style={{}}>
              The First Truly Global Transaction Categorizer
            </h2>
          </div>
        </Col>
      </Row>
      <div
        class="mt-20 grid  grid-cols-3 gap-2 gap-y-20 w-full sm:w-4/5 p-4 sm:p-0 mx-auto relative privacy_privacy__dEIY7"
        style={{ gridTemplateColumns: "1fr auto 1fr" }}
      >
        <svg
          width="1px"
          height="431px"
          viewBox="0 0 1 431"
          version="1.1"
          class="absolute global-trans-svg left-1/2 z-0 -mt-10"
        >
          <g
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            opacity="0.550967262"
            stroke-dasharray="30,20"
            stroke-linecap="round"
          >
            <g
              transform="translate(-712.000000, -2851.000000)"
              stroke="#5985FA"
            >
              <line
                x1="712.5"
                y1="2851.5"
                x2="712.5"
                y2="3287.5"
                id="Line"
              ></line>
            </g>
          </g>
        </svg>
        <div class="text-right text-xl">
          <div
            class="Plx Plx--below "
            style={{
              willChange: "opacity, transform",
              transform: "translate(1vw,0px)",
              opacity: 1,
            }}
          >
            <p class="-mt-5 mr-5 sm:mt-0" style={{ textAlign: "right" }}>
              Turnkey: plug and play API. <strong> From day 1</strong>. No
              training needed.
            </p>
          </div>
        </div>
        <div
          style={{
            zIndex: "3",
            // display: "flex",
            // alignItems: "center",
            // flexDirection: "column",
          }}
        >
          {/* <AE title="United States" className="flag" />
          <EG title="United States" className="flag" /> */}
        </div>
        <div></div>
        <div></div>
        <div
          className="flag-container justify-center items-center flex mb-4"
          ref={flagRef}
          style={{
            width: "clamp(40px, 12vw, 160px)",
            zIndex: "3",
            // display: "flex",
            // alignItems: "center",
            // flexDirection: "column",
          }}
        >
          {flags.at(currentFlagIdx)}
        </div>

        <div class="text-xl justify-center items-center flex">
          <div class="Plx Plx--below ">
            <p class="flex flex-col ml-5 sm:block -mt-5 sm:mt-0">
              Country-agnostic: we enrich transaction from{" "}
              <strong class="tabular-nums font-mono">
                <TypeWriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("all countries")
                      .pauseFor(500)
                      .deleteAll()
                      .typeString("almost all countries.")
                      .start();
                  }}
                />
              </strong>
            </p>
          </div>
        </div>
        <div class="text-right text-xl">
          <div
            class="Plx Plx--active Plx--in Plx--in-0 "
            style={{
              willChange: "opacity, transform",
              transform: "translate(1vw,0px)",
              opacity: 1,
            }}  
          >
            <p class="-mt-5 mr-5 sm:mt-0" style={{ textAlign: "right" }}>
              <div>
                A single API endpoint,
                <br /> 5 enrichment fields.
              </div>
            </p>
          </div>
        </div>
        <div>
          {/* <div
            className="rotating-sphere"
            style={{
              height: "1vh",
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <Sphere />
          </div> */}
        </div>
        <div></div>
      </div>
    </Container>
  );
};

export default CategorizationAd;
